var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("hibah-product")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 offset-md-2 mt-4 pt-2"
  }, [_vm.error == 1 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t("select-product")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Client' ? _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(12);
      }
    }
  }, [_vm._m(0)])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(1);
      }
    }
  }, [_vm._m(1)])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(3);
      }
    }
  }, [_vm._m(2)])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(5);
      }
    }
  }, [_vm._m(3)])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(7);
      }
    }
  }, [_vm._m(4)])]), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        return _vm.pushRedirect(9);
      }
    }
  }, [_vm._m(5)])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("Hibah One Cent")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" Informasi Deklarasi Hibah One Cent ")])])]), _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(" Deklarasi Hibah Amanah Korporat ")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" Informasi Deklarasi Hibah Amanah Korporat ")])])]), _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("Deklarasi Hibah Amanah Tunai")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" Informasi Deklarasi Hibah Amanah Tunai ")])])]), _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(" Deklarasi Hibah Amanah Hartanah ")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" Informasi Deklarasi Hibah Amanah Hartanah ")])])]), _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("Hibah Amanah Tunai")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" Informasi Hibah Amanah Tunai ")])])]), _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v("Hibah Amanah Hartanah")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "h6 text-muted mb-0"
  }, [_vm._v(" Informasi Hibah Amanah Hartanah ")])])]), _c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])]);
}]

export { render, staticRenderFns }